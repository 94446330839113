import React, { useState } from 'react';
import {ModalBody, Row, Col, Label, Input, Button} from 'reactstrap';
import IpAddressForm from './ipAddressForm';

const mediablockFields = [
    { label: 'Product Name', name: 'productName' },
    { label: 'Software Version', name: 'softwareVersion' },
    { label: 'Firmware Version', name: 'firmwareVersion' },
    { label: 'Dowser', name: 'dowser' },
    { label: 'Lamp', name: 'lamp' },
    { label: 'State', name: 'state' },
    { label: 'Capacity', name: 'capacity', type: 'number' },
    { label: 'Used Disk Space', name: 'usedDiskSpace', type: 'number' },
];


const MediablockProperties = ({ initialSettings, onSave }) => {
    const [settings, setSettings] = useState(initialSettings || {});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSettings((prevSettings) => ({
            ...prevSettings,
            [name]: value,
        }));
    };

    const renderField = field => (
        <Col md={6} key={field.name}>
            <h5 className="mt-3 text-uppercase" >{field.label}</h5>
            <div style={{'color': '#ffffff80'}}>{initialSettings[field.name] || `no ${field.name} available`}</div>
        </Col>
    );

    return (
        <ModalBody>
            <Row>
                {mediablockFields.map(renderField)}
                <Col md={6}>
                    <h5 className="mt-3 text-uppercase" >Status</h5>
                    <div style={{'color': '#ffffff80'}}>{initialSettings?.status}</div>
                </Col>
            </Row>

            <Row className="mt-3">
                <Col className="d-flex flex-row align-items-center">
                    <Label for="serialNumber" style={{ width: '250px' }}>
                        <span className="fas fa-barcode fa-fw me-2 text-primary"/>
                        Serial Number
                    </Label>
                    <Input
                        id="serialNumber"
                        name="serialNumber"
                        value={settings.serialNumber}
                        onChange={handleChange}
                    />
                </Col>
            </Row>

            <Row>
                <Col>
                    <IpAddressForm handleChange={handleChange} settings={settings}/>
                </Col>
            </Row>

            <div className="d-flex justify-content-end mt-3">
                <Button color="primary" outline onClick={() => onSave(settings)}>
                    <span className="fas fa-save me-2" /> Save & Close
                </Button>
            </div>
        </ModalBody>
    );
};

export default MediablockProperties;
