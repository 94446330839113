export const nestedSearch =(arr, searchFields, query)=>{
    if(Array.isArray(arr)) {
        const filteredArray = arr.filter(item =>{
            const lowerCaseQuery = query.toLowerCase();
            return searchFields.some(field => {
                if (typeof field === 'string') {
                        return item[field] && item[field].toLowerCase().includes(lowerCaseQuery);
                    } else if (typeof field === 'object' && field.path && field.fields) {
                        const nestedObject = field.path.split('.').reduce((obj, key) => (obj && obj[key] ? obj[key] : null), item);
                        if (nestedObject) {
                            return field.fields.some(nestedField => nestedObject[nestedField] && nestedObject[nestedField].toLowerCase().includes(lowerCaseQuery));
                        }
                    }
                return false;
            });
        })
        return filteredArray
    }
}