import React from 'react';
import {Card, CardFooter} from 'reactstrap';
import OrderStatusBadge from '../OrderStatusBadge';

import './MovieThumb.scss';
import {imgUrl} from '../../utils/api-request';

const MovieThumb = ({ width = '162px', movie, version, orderStatus, animatedFooter }) => {
    return (
        <Card className={animatedFooter ? `movie-card pointer ${'animatedFooter'}`:`movie-card`} style={{ width }}>
            <img
                className="object-fit-cover w-100"
                alt={movie.title}
                src={imgUrl(movie.poster?.imageUrl)}
            />

            {process.env.REACT_APP_ENVIRONMENT === 'development' && (
                <span className="position-absolute bg-danger p-1 opacity-75 small text-white">
                    {version && version.contentRestrictions ? `${version.contentRestrictions}+` : 'N/A'}
                </span>
            )}

            <CardFooter className="d-flex p-0">
                {orderStatus && <OrderStatusBadge status={orderStatus} />}
                {!orderStatus && <div className="text-secondary d-flex flex-column">
                    <div>
                        {movie.status === "published" && <span className="fa fa-check text-success my-2 mx-2 " /> }
                        {movie.status === "draft" && <span className="fa fa-pencil text-danger my-2 mx-2" /> }
                        {movie.status}
                        {(movie.versionsCount > 0 /* || movie.DCPtrailer */) && <span className="fa fa-ellipsis-vertical text-secondary my-2 ms-4 ellipsis" />}
                    </div>
                    <div className="hover-info">
                        {movie.versionsCount > 0 && <div><span className="fa fa-film text-secondary my-2 mx-2" />
                            {movie.versionsCount} version{movie.versionsCount !== 1 && 's'}
                        </div>}
                        <div><span className="fa fa-play text-secondary my-2 mx-2" />DCP Trailer</div>
                    </div>

                </div> }
            </CardFooter>


        </Card>
    );
};
export default MovieThumb;
