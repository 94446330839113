import React, { useState, useEffect } from 'react';
import {Button, Form, FormGroup, Label, Input, ButtonGroup, Row, Col, ModalFooter, ModalBody} from 'reactstrap';

const CinemaProperties = ({ initialSettings, onSave }) => {
    const [cinemaSettings, setCinemaSettings] = useState(initialSettings);
    const [customAudioType, setCustomAudioType] = useState('');

    useEffect(() => {
        if (cinemaSettings?.audioType !== '5.1' && cinemaSettings?.audioType !== '7.1') {
            setCustomAudioType(cinemaSettings?.audioType || '');
        }
    }, [cinemaSettings?.audioType]);

    const handleSelectChange = (field, value) => {
        setCinemaSettings((prevSettings) => ({
            ...prevSettings,
            [field]: value,
        }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'customAudioType') {
            setCinemaSettings((prevSettings) => ({
                ...prevSettings,
                audioType: value,
            }));
            setCustomAudioType(value);
        } else {
            setCinemaSettings((prevSettings) => ({
                ...prevSettings,
                [name]: value,
            }));
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCinemaSettings((prevSettings) => ({
            ...prevSettings,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSave(cinemaSettings);
    };

    const renderButtonOption = (field, optionValue, displayValue) => (
        <Button
            key={optionValue}
            className="px-3"
            color="primary"
            outline={cinemaSettings?.[field] !== optionValue}
            onClick={() => handleSelectChange(field, optionValue)}
        >
            {displayValue}
        </Button>
    );

    return (
        <Form onSubmit={handleSubmit}>
            <ModalBody>
                <Row className="mb-3">
                    <Col>
                        <FormGroup>
                            <Label for="languageSpoken">
                                <span className="fas fa-language me-2 text-primary" title="Spoken Language"/>
                                Spoken Language
                            </Label>
                            <Input
                                id="languageSpoken"
                                name="languageSpoken"
                                value={cinemaSettings?.languageSpoken}
                                onChange={handleChange}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="languageSubtitle">
                                <span className="fas fa-closed-captioning me-2 text-primary" title="Spoken Language"/>
                                Subtitle Language
                            </Label>
                            <Input
                                id="languageSubtitle"
                                name="languageSubtitle"
                                value={cinemaSettings?.languageSubtitle}
                                onChange={handleChange}
                            />
                        </FormGroup>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col>
                        <FormGroup>
                            <Label className="d-block">
                                <span className="fas fa-volume-up me-2 text-primary" title="Audio Type"/>
                                Audio Type
                            </Label>
                            <ButtonGroup>
                                {renderButtonOption('audioType', '5.1', '5.1')}
                                {renderButtonOption('audioType', '7.1', '7.1')}
                                <Input
                                    type="text"
                                    name="customAudioType"
                                    value={customAudioType}
                                    onChange={handleInputChange}
                                    placeholder="Other"
                                    className="border-primary"
                                    style={{ height: '38px', width: '100px' }}
                                />
                            </ButtonGroup>

                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label className="d-block">
                                <span className="fas fa-expand me-2 text-primary" />
                                Aspect Ratio
                            </Label>
                            <ButtonGroup>
                                {renderButtonOption('aspectRatio', 'flat', 'Flat')}
                                {renderButtonOption('aspectRatio', 'scope', 'Scope')}
                            </ButtonGroup>
                        </FormGroup>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col>
                        <FormGroup>
                            <Label className="d-block">
                                <span className="fas fa-desktop me-2 text-primary" />
                                Resolution
                            </Label>
                            <ButtonGroup>
                                {renderButtonOption('resolution', '2K', '2K')}
                                {renderButtonOption('resolution', '4K', '4K')}
                            </ButtonGroup>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label className="d-block">
                                <span className="fas fa-film me-2 text-primary" />
                                Framerate
                            </Label>
                            <ButtonGroup>
                                {['24', '25', '48'].map(fps => renderButtonOption('framerate', fps, `${fps} fps`))}
                            </ButtonGroup>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>

            <ModalFooter>
                <Button type="submit" color="primary" className="float-end">Save Changes</Button>
            </ModalFooter>
        </Form>
    );
};

export default CinemaProperties;
