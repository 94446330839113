import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {fetchLoggedUser, stopImpersonating} from '../../slices/auth.slice';
import Sidebar from './Sidebar';
import NavbarComponent from './NavbarComponent';
import PinCode from './PinCode';

import './Layout.scss';
import {Button} from 'reactstrap';
import Loading from '../../components/Loading';
import {fetchOrganization} from '../../slices/organizations.slice';
import {getPlayingMovie} from '../../slices/play-movie.slice';
import MovieControl from '../../components/MovieControl';
import {fetchNotifications} from '../../slices/notifications.slice';

const Layout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isImpersonating = useSelector((state) => state.auth.isImpersonating);
    const token = useSelector((state) => state.auth.token);
    const originalToken = useSelector((state) => state.auth.originalToken);
    const user = useSelector((state) => state.auth.user);
    const { organization } = useSelector(state => state.organizations);
    const { playing } = useSelector(state => state.playMovie);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const isLoading = useSelector((state) => state.auth.isLoading);
    const requirePinCode = useSelector((state) => state.auth.requirePinCode);
    const [isSidebarOpen, setSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        console.log({isSidebarOpen});
        setSidebarOpen(!isSidebarOpen);
    };

    const handleStopImpersonating = () => {
        dispatch(stopImpersonating());
        dispatch(fetchLoggedUser(originalToken));
        const impersonatedUserId = localStorage.getItem('impersonatedUser');
        navigate(`/users/${impersonatedUserId}`);
        localStorage.removeItem('impersonatedUser');
    }

    useEffect(() => {
        if (user && user.parentOrganization) {
            dispatch(fetchOrganization({ orgId: user.parentOrganization._id }));
            dispatch(getPlayingMovie());
        }
    }, [dispatch, user]);

    useEffect(() => {
        if (!isAuthenticated) {
            if (token) {
                dispatch(fetchLoggedUser(token));
                dispatch(fetchNotifications());
            } else {
                navigate('/login');
            }

        }
    }, [token, dispatch, navigate, isAuthenticated]);

    if (isLoading) {
        return <Loading fullHeight />;
    }

    if (requirePinCode) {
        return <PinCode />;
    }

    return (
        <div className={`text-white layout-parent bg-broken position-relative${isImpersonating? ' impersonating' : ''}`}>
            <NavbarComponent isOpen={isSidebarOpen} toggle={toggleSidebar} />

            <div className="d-flex">
                <Sidebar isOpen={isSidebarOpen} toggle={toggleSidebar} organization={organization} />
                <div className={`main-content ${playing ? 'playing' : ''}`}>
                    <Outlet/>
                </div>
            </div>

            {playing && (
                <div className="player position-fixed w-100 h-100 top-0 start-0 d-flex flex-column p-5">
                    <MovieControl playing={playing} movieId={playing.movie._id} orderId={playing._id} />
                </div>
            )}

            {isImpersonating && user && (
                <div className="impersonating-container position-fixed w-100 h-100 top-0 start-0 d-flex flex-column">
                    <div className="flex-grow-1"></div>
                    <div className="toolbar d-flex align-items-center px-3 text-black justify-content-between">
                        <span className="lead text-white fw-bold text-uppercase d-none d-md-inline-block">
                            <span className="fas fa-warning me-2 text-warning"/>
                            WARNING
                        </span>

                        <span className="lead text-white fw-bold text-uppercase">
                            <span className="d-none d-md-inline-block">Impersonating as</span> {user.firstName} {user.lastName}
                        </span>

                        <span className="lead text-white fw-bold text-uppercase d-none d-md-inline-block">
                            {user.parentOrganization.name}
                        </span>

                        <Button color="light" onClick={handleStopImpersonating}>
                            <span className="fas fa-stop text-danger me-2"/>Stop
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Layout;
