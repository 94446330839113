import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PageHeader from '../../components/PageHeader';
import {nestedSearch} from '../../utils/nestedSearch';
import {fetchAuditLogActions, fetchAuditLogs} from '../../slices/audit-logs.slice';
import Loading from '../../components/Loading';
import {useLocation} from 'react-router-dom';

const Homepage = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { list: auditLogs, actions, status } = useSelector(state => state.auditLogs);
    const [filteredValues, setFilteredValues] = useState([]);
    const [filterBy, setFilterBy] = useState({});
    const [searchValue, setSearchInput] = useState('');

    useEffect(() => {
        dispatch(fetchAuditLogs(filterBy))
    }, [dispatch, filterBy]);

    useEffect(() => {
        setFilteredValues(auditLogs);
    }, [auditLogs]);

    useEffect(() => {
        dispatch(fetchAuditLogActions());
    }, [dispatch]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const filters = {};

        for (const [key, value] of searchParams.entries()) {
            if (key === 'search') {
                filters.search = value;

            } else if (key === 'actions') {
                filters.actions = value.split(',');
            } else if (key === 'from') {
                filters.from = [value];
            } else if (key === 'until') {
                filters.until = [value];
            }
        }

        setFilterBy(filters);
    }, [location.search]);

    const handleSearch = (event)=>{
        event.preventDefault();
        const query = event.target.value.toLowerCase()
        setSearchInput(query);
        const searchFields = [
            'details'
        ]
        let filteredData = nestedSearch([...auditLogs], searchFields, query)
        setFilteredValues(filteredData);
    };

    const options = {
        actions: [...new Set(Object.keys(actions))],
        'screening date': {},
    };

    return (
        <div className="auditlog-view px-4 py-3 d-flex flex-column vh-100 overflow-y-auto">
            <PageHeader
                title="Audit Logs"
                searchBar={{searchValue, handleSearch}}
                filters={{filterBy, options }}
            />

            {status === 'loading' && <Loading length={3} />}

            {status === 'success' && (
                <div>
                    {filteredValues.map(({ _id, action, entity, details, createdAt, organization, user, }) => (
                        <div key={_id} className="my-2 p-3 bg-broken border-start border-primary d-flex justify-content-between">
                            <div>
                                <p className="strong mb-0 text-primary">
                                    <span className="fas fa-clipboard-list me-2"/>
                                    {action}
                                </p>
                                <p className="mb-0">{details}</p>
                            </div>

                            <div className="small">
                                <p className="mb-0 text-primary">
                                    <span className="fas fa-calendar me-2"/>
                                    {new Date(createdAt).toLocaleString()}
                                </p>
                                <p className="mb-0">
                                    {user && (
                                        <span className="me-3">
                                            <span className="fas fa-user me-1"/> {user.firstName} {user.lastName}
                                        </span>
                                    )}
                                    {organization && (
                                        <Fragment>
                                            <span className="fas fa-building me-1"/> {organization.name}
                                        </Fragment>
                                    )}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Homepage;
