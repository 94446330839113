import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import api from '../api';

export const login = createAsyncThunk('auth/login', async ({ username, password }) => {
    const response = await api.post('/auth/login', { username, password });
    localStorage.setItem('token', response.data.token);
    localStorage.setItem('refreshToken', response.data.refreshToken);
    return response.data;
});

export const refreshToken = createAsyncThunk('auth/refreshToken', async (pinCode) => {
    const response = await api.post('/auth/refreshToken', { pinCode }, { refreshToken: true });
    return response.data;
});

export const requestImpersonation = createAsyncThunk('auth/impersonate', async ({ userId }) => {
    const response = await api.post('/auth/impersonate', { userId });
    localStorage.setItem('originalToken', localStorage.getItem('token'));
    localStorage.setItem('token', response.data.token);
    localStorage.setItem('isImpersonating', '1');
    return response.data;
});

export const fetchLoggedUser = createAsyncThunk('auth/fetchLoggedUser', async () => {
    const response = await api.get('/auth/me');
    return response.data;
});

export const updateUser = createAsyncThunk('auth/updateUser', async (body) => {
    const response = await api.put('/auth/me', body);
    return response.data;
});

export const registerUser = createAsyncThunk('user/register', async ({ userBody }) => {
    const response = await api.post('/auth/register', userBody);
    return response.data;
});

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        token: localStorage.getItem('token'),
        refreshToken: localStorage.getItem('refreshToken'),
        pinError: false,
        requirePinCode: false,
        isImpersonating: !!localStorage.getItem('isImpersonating'),
        originalUser: null,
        originalToken: localStorage.getItem('originalToken'),
        isAuthenticated: false,
        isLoading: false,
        error: null,
        createUserStatus: null,
        lastCreatedUser: null,
        createUserErrors: [],
    },
    reducers: {
        requirePinCode: (state, action) => {
            state.requirePinCode = true;
            state.retryRequest = action.payload;
        },
        clearPinError: (state) => {
            state.pinError = false;
        },
        logout: (state) => {
            state.user = null;
            state.token = null;
            state.refreshToken = null;
            state.originalUser = null;
            state.originalToken = null;
            state.isAuthenticated = false;
            state.isImpersonating = false;
        },
        stopImpersonating: (state) => {
            state.token = state.originalToken;
            state.isImpersonating = false;
            localStorage.setItem('token', localStorage.getItem('originalToken'));
            localStorage.removeItem('originalToken');
            localStorage.removeItem('isImpersonating');
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.fulfilled, (state, action) => {
                state.token = action.payload.token;
                state.refreshToken = action.payload.refreshToken;
                state.user = action.payload.user;
                state.isLoading = false;
                state.isAuthenticated = true;
            })
            .addCase(login.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(login.rejected, (state, action) => {
                state.isLoading = false;
                state.isAuthenticated = false;
                state.error = action?.error?.message;
            })

            .addCase(refreshToken.pending, (state) => {
                state.pinError = false;
            })
            .addCase(refreshToken.fulfilled, (state, action) => {
                localStorage.setItem('token', action.payload.accessToken);
                localStorage.setItem('refreshToken', action.payload.refreshToken);
                state.requirePinCode = false;
                state.pinError = false;
            })
            .addCase(refreshToken.rejected, (state) => {
                state.isAuthenticated = false;
                state.pinError = true;
            })

            .addCase(requestImpersonation.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(requestImpersonation.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isImpersonating = true;
                state.originalUser = state.user;
                state.originalToken = state.token;
                state.user = action.payload.user;
                state.token = action.payload.token;
            })
            .addCase(requestImpersonation.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action?.error?.message;
            })

            .addCase(fetchLoggedUser.fulfilled, (state, action) => {
                state.user = action.payload;
                state.isAuthenticated = true;
            })

            .addCase(updateUser.fulfilled, (state, action) => {
                state.user = action.payload;
            })

            .addCase(registerUser.pending, (state) => {
                state.createUserStatus = 'loading';
            })
            .addCase(registerUser.fulfilled, (state, action) => {
                state.createUserStatus = 'succeeded';
                state.lastCreatedUser = action.payload;
                state.error = null;
            })
            .addCase(registerUser.rejected, (state, action) => {
                state.createUserStatus = 'failed';
                state.createUserErrors = action.payload?.errors;
            })
        ;
    },
});

export const {
    logout,
    stopImpersonating,
    requirePinCode,
    clearPinError,
} = authSlice.actions;

export default authSlice.reducer;
