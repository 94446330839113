import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchOrganization} from '../../slices/organizations.slice';
import {Col, Row} from 'reactstrap';
import DiskUsage from '../../components/DiskUsage';

const bacServerFields = [
    { label: 'Daemon Alfred', name: 'gofilexDaemonsAlfred', onlineIcon: true },
    { label: 'Daemon Frank', name: 'gofilexDaemonsFrank', onlineIcon: true },
];

const mediablockFields = [
    { label: 'Status', name: 'status', onlineIcon: true },
    { label: 'State', name: 'state', onlineIcon: true },
    { label: 'Product Name', name: 'productName' },
    { label: 'Serial Number', name: 'serialNumber' },
    { label: 'Software Version', name: 'softwareVersion' },
    { label: 'Firmware Version', name: 'firmwareVersion' },
    { label: 'Dowser', name: 'dowser' },
    { label: 'Lamp', name: 'lamp' },
];

const Equipment = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);
    const { organization, organizationStatus } = useSelector(state => state.organizations);

    useEffect(() => {
        if (user) {
            dispatch(fetchOrganization({ orgId: user.parentOrganization._id }));
        }
    }, [dispatch, user]);

    return organizationStatus === 'success' && organization && (
        <div className="p-3 vh-100">
            <h1>Equipment status</h1>

            <div className="connection my-3 small">
                <span className="fas fa-fw fa-clock me-2 pt-1" />
                Last sync: {new Date(organization.lastSynced).toLocaleString()}
            </div>

            <div className="my-4 text-uppercase">
                <h4 className="text-primary">
                    <span className="fas fa-fw fa-cube me-2" />
                    mediablock
                </h4>
                <div className="info text-lowercase p-3 bg-broken text-capitalize border-start border-primary border-3">
                    <Row>
                        {mediablockFields.map((field) => (
                            <Col md={6} key={field.name}>
                                <div className="mt-3 text-uppercase">{field.label}</div>
                                <div style={{'color': '#ffffff80'}}>
                                    {field.onlineIcon && (
                                        <span className={`fas me-2
                                        ${['Enabled', 'Connected'].includes(organization.mediablock[field.name]) ? 'fa-check-circle text-success' : 'fa-times-circle text-danger'}`} />
                                    )}
                                    {organization.mediablock[field.name] || `no ${field.name} available`}
                                </div>
                            </Col>
                        ))}
                    </Row>

                    <div className="my-3">
                        <div className="mt-3 text-uppercase text-primary mb-2">Storage capacity</div>
                        <DiskUsage
                            capacity={organization.mediablock.capacity}
                            usedDiskSpace={organization.mediablock.usedDiskSpace}
                        />
                    </div>
                </div>
            </div>

            <div className="my-4 text-uppercase pt-3 mb-5">
                <h4 className="text-primary">
                    <span className="fas fa-fw fa-server me-2"/>
                    BAC server
                </h4>
                <div className="info text-lowercase p-3 bg-broken text-capitalize border-start border-primary border-3">
                    <Row>
                        {bacServerFields.map((field) => (
                            <Col md={6} key={field.name}>
                                <div className="mt-3 text-uppercase">{field.label}</div>
                                <div style={{'color': '#ffffff80'}}>
                                    {field.onlineIcon && (
                                        <span className={`fas me-2
                                        ${organization.bacServer[field.name] === 'online' ? 'fa-check-circle text-success' : 'fa-times-circle text-danger'}`} />
                                    )}
                                    {organization.bacServer[field.name] || `no ${field.name} available`}
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>
            </div>
            <br/>
            <br/>
        </div>
    );
};

export default Equipment;
