import React, {useCallback, useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    playMovie,
    pauseMovie,
    getPlayStatus,
    ejectMovie,
    getPlayingMovie, goToTimecode,
} from '../slices/play-movie.slice';
import {imgUrl} from '../utils/api-request';
import MovieControlButtons from "./MovieControlButtons";
import {Progress} from 'reactstrap';


const MovieControl = ({ playing, movieId, orderId }) => {
    const dispatch = useDispatch();
    const { status, playStatus, showStatus } = useSelector((state) => state.playMovie);
    const [playingCursor, setPlayingCursor] = useState(0);
    const [lastUpdateTime, setLastUpdateTime] = useState(Date.now());

    const refreshPlayStatus = useCallback(async () => {
        await dispatch(getPlayStatus({ movieId, orderId })).unwrap();

        if (showStatus?.spl_position !== undefined) {
            setPlayingCursor(showStatus.spl_position);
            setLastUpdateTime(Date.now());  // Mark the time of the update
        }
    }, [dispatch, movieId, orderId, showStatus?.spl_position]);

    const handlePlay = async () => {
        const { spl_position } = showStatus;
        if (playStatus === 'Pause' || showStatus?.spl_position > 5) {
            await dispatch(goToTimecode({
                movieId,
                orderId,
                timecode: spl_position - 5,
            })).unwrap();
        } else {
            await dispatch(playMovie({ movieId, orderId })).unwrap();
        }

        return refreshPlayStatus();
    };

    const handlePause = () => {
        dispatch(pauseMovie({ movieId, orderId }));
        return refreshPlayStatus();
    };

    const handleStop = async () => {
        await dispatch(ejectMovie({ movieId, orderId })).unwrap();
        await dispatch(getPlayStatus({ movieId, orderId }));
        return refreshPlayStatus();
    };

    const calculatePercentage = () => {
        if (showStatus) {
            if (showStatus.spl_duration === 0) {
                return 0;
            }
            return (showStatus.spl_position / showStatus.spl_duration) * 100;
        }

        return null;
    };

    const formatTime = (seconds) => {
        if (isNaN(seconds) || seconds === null || seconds === undefined) {
            return '--:--:--';
        }

        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = Math.floor(seconds % 60);

        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    };

    const handleJump = async (direction, seconds) => {
        const promise = new Promise(async (resolve, reject) => {
            try {
                const { spl_position } = showStatus;
                const timecode = direction === 'backward' ? spl_position - seconds : spl_position + seconds;
                await dispatch(goToTimecode({ movieId, orderId, timecode })).unwrap();
                resolve();
            } catch (error) {
                reject(error);
            }
        });
        await promise;
        return refreshPlayStatus();
    };

    useEffect(() => {
        dispatch(getPlayingMovie());
        dispatch(getPlayStatus({ movieId, orderId }));
    }, [dispatch, movieId, orderId]);

    useEffect(() => {
        const refreshIntervalId = setInterval(() => {
            refreshPlayStatus();
        }, 10 * 1000);

        return () => {
            clearInterval(refreshIntervalId);
        };
    }, [refreshPlayStatus]);

    useEffect(() => {
        let cursorIntervalId;

        if (playStatus === 'Play') {
            cursorIntervalId = setInterval(() => {
                const timeElapsed = (Date.now() - lastUpdateTime) / 1000;

                setPlayingCursor(showStatus?.spl_position + timeElapsed);
            }, 1000);
        }

        return () => {
            if (cursorIntervalId) {
                clearInterval(cursorIntervalId);
            }
        };
    }, [playStatus, showStatus?.spl_position, lastUpdateTime]);

    return (
        <div className="player-container">
            <div className="ps-5 pt-4 d-flex justify-content-start gap-3">
                <div>
                    {playing.movie.poster && (
                        <img
                            className="logo-img"
                            width={75}
                            alt={playing.movie.title}
                            src={imgUrl(playing.movie.poster.imageUrl)}
                        />
                    )}
                </div>

                <div className="flex-grow-1">
                    <h4 className="text-primary mt-3 mb-0">{playing.movie.title}</h4>

                    <MovieControlButtons
                        status={status}
                        playStatus={playStatus}
                        handlePlay={handlePlay}
                        handlePause={handlePause}
                        handleJump={handleJump}
                        handleStop={handleStop}
                    />

                    {showStatus && (
                        <div className="pe-3">
                            <div className="d-flex justify-content-between small text-center">
                                <span>{formatTime(playingCursor)}</span>
                                <span>{formatTime(showStatus.spl_duration)}</span>
                            </div>
                            <Progress
                                value={calculatePercentage()}
                                animated={playStatus === 'Play'}
                                color="success"
                                style={{height: '7px'}}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MovieControl;
