import './DistributorPage.scss';
import React, {Fragment, useEffect, useState} from 'react';
import {NavLink, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Button, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import Avatar from '../../components/Avatar';
import {imgUrl} from '../../utils/api-request';
import EditModal from '../../components/EditModal';
import countries from '../Movies/countries.json';
import DefaultsTable from './DefaultsTable';
import Countries from '../Movies/countries.json';
import CountrySelect from '../../components/CountrySelect';
import {fetchDistributor, updateDistributor} from '../../slices/distributors.slice';

const {regions} = countries;

const DistributorPage = () => {
    const dispatch = useDispatch();
    const { distributorId } = useParams();
    const { current: distributor, error } = useSelector(state => state.distributors);
    const [expandedCountryCode, setExpandedCountryCode] = useState(null);
    const [deleteModal, setDeleteModal] = useState({ isOpen: false, code: null });
    const [addCountriesModal, setAddCountriesModal] = useState(false);
    const [editModal, setEditModal] = useState({
        open: false,
        field: '',
        dataType: 'text',
        label: '',
        value: '',
        mediaType: '',
    });

    const toggleEditModal = (field, dataType, label, value, mediaType, list) => {
        setEditModal({
            open: true,
            field,
            dataType,
            label,
            value,
            mediaType,
            list,
        });
    };

    const handleUpdateDistributor = async (updatedDistributor, success) => {
        const promise = dispatch(updateDistributor(updatedDistributor)).unwrap();
        toast.promise(promise, {
            pending: 'Updating distributor...',
            success,
            error: `Error updating distributor.`,
        });
        return promise;
    };

    const onSaveEditModal = async (newValue) => {
        let updatedDistributor = { ...distributor };
        let successMessage = 'Distributor updated';

        if (editModal.field === 'logo') {
            updatedDistributor.logo = newValue._id;
            successMessage = 'Distributor logo uploaded and updated';
        } else {
            updatedDistributor[editModal.field] = newValue;
        }

        await handleUpdateDistributor(updatedDistributor, successMessage);
        setEditModal(prev => ({ ...prev, open: false }));
    };

    const toggleHirePeriodType = async () => {
        try {
            const updatedDistributor = {
                ...distributor,
                hirePeriodType: distributor.hirePeriodType === 'hours' ? 'days' : 'hours',
            };
            await handleUpdateDistributor(updatedDistributor, 'Distributor updated');
        } catch (e) {
            console.error('Error updating distributor', e);
        }
    };

    const toggleExpand = (countryCode) => {
        if (expandedCountryCode === countryCode) {
            setExpandedCountryCode(null);
        } else {
            setExpandedCountryCode(countryCode);
        }
    };

    const showDeleteModal = (e, code) => {
        e.stopPropagation();
        setDeleteModal({ isOpen: true, code });
    };

    const closeModal = e => {
        e.preventDefault();
        setDeleteModal({ isOpen: false, version: null });
    };

    const handleDeleteCountry = async code => {
        const updatedDistributor = {
            ...distributor,
            countries: distributor.countries.filter(({ countryCode }) => countryCode !== code),
        };
        const successMessage = `${regions.find(({code: cc}) => cc === code).name} removed`;
        handleUpdateDistributor(updatedDistributor, successMessage)
            .then(() => setDeleteModal({ isOpen: false, version: null }))
            .catch(e => console.error('Error updating distributor', e));
    };

    const handleSaveDefaults = defaults => {
        const updatedDistributor = { ...distributor, distributorDefaults: defaults };
        handleUpdateDistributor(updatedDistributor, 'Defaults updated')
            .catch(e => console.error('Error updating defaults', e));
    };

    const handleAddCountries = async countries => {
        const updatedDistributor = {
            ...distributor,
            countries: [
                ...distributor.countries,
                ...countries.map(countryCode => ({
                    countryCode,
                    defaults: distributor.distributorDefaults,
                }))
            ],
        };
        const successMessage = `Added ${countries.length} countries and applied the default settings.`;
        handleUpdateDistributor(updatedDistributor, successMessage)
            .catch(e => console.error('Error adding countries', e));
    };

    const handleSaveCountry = index => async value => {
        const updatedDistributor = {
            ...distributor,
            countries: [
                ...distributor.countries.slice(0, index),
                {
                    ...distributor.countries[index],
                    defaults: value,
                },
                ...distributor.countries.slice(index + 1),
            ],
        };
        updatedDistributor.countries[index].defaults = value;
        handleUpdateDistributor(updatedDistributor, 'Updated successfully')
            .catch(e => console.error('Error updating country', e));
    };

    useEffect(() => {
        dispatch(fetchDistributor(distributorId));
    }, [dispatch, distributorId]);

    return (
        <div className="distributors-page-view p-3 min-vh-100 mb-5">
            <NavLink to="/distributors" className="text-decoration-none">
                <span className="fas fa-chevron-left me-2"/> Distributors
            </NavLink>

            {error && (
                <div className="text-center text-uppercase text-expand mt-5">
                    <span className="fas fa-warning fa-3x opacity-50 text-danger my-3 pulse-effect" /><br/>
                    Failed to fetch distributor details <br/>
                    <code>{error}</code>
                </div>
            )}

            {distributor && (
                <Fragment>
                    <div className="distributor-header d-flex align-items-center gap-3 my-3 bg-broken p-3 border-start border-primary">
                        <div>
                            <Avatar
                                imageUrl={distributor.logo?.imageUrl}
                                name={distributor.name}
                                onEdit={() => toggleEditModal('logo', 'image', 'logo', imgUrl(distributor.logo?.imageUrl), 'distributor')}
                            />

                        </div>
                        <div>
                            <h1 className="my-0 is-edit"
                                onClick={() => toggleEditModal('name', 'text', 'Distributor Name', distributor.name)}>
                                {distributor.name}
                            </h1>
                            <p className="mb-0">
                                <span className="text-uppercase">Default hire period: </span>
                                <span className="is-edit"
                                      onClick={() => toggleEditModal('defaultHirePeriod', 'number', 'Default Hire Period', distributor.defaultHirePeriod)}>
                                    <strong>{distributor.defaultHirePeriod} {distributor.hirePeriodType}</strong>
                                </span>
                                <Button color="link" onClick={toggleHirePeriodType}>
                                    <span className="fas fa-refresh me-2" />
                                    change to {distributor.hirePeriodType === 'hours' ? 'days' : 'hours'}
                                </Button>
                            </p>
                        </div>
                    </div>

                    <div className="defaults-container bg-broken border-start border-primary p-3 mb-2">
                        <p className="text-primary mb-0">
                            <span className="fas fa-info-circle me-2" /> DEFAULT SETTINGS INSTRUCTIONS
                        </p>
                        <p>
                            Define the default global settings for {distributor.name}. These settings will serve as the baseline for each country you add subsequently.
                            <br/>
                            Once a country has been added, you have the flexibility to modify its specific settings as needed.
                        </p>

                        <DefaultsTable onSave={handleSaveDefaults} defaults={distributor.distributorDefaults} />
                    </div>

                    <ListGroup className="countries-default mb-5">
                        <ListGroupItem className="d-flex flex-column align-items-start bg-broken text-white my-2">
                            <div className="d-flex align-items-center w-100 header"
                                 onClick={() => setAddCountriesModal(!addCountriesModal)}>
                                <span className="fas fa-plus me-3 fa-2x"/>
                                <span className="pt-1">Add countries</span>
                            </div>
                        </ListGroupItem>

                        {distributor.countries.map((country, index) => (
                            <ListGroupItem
                                className={`d-flex flex-column align-items-start bg-broken text-white my-2
                                            ${expandedCountryCode === country.countryCode ? ' expanded' : ''}`}
                                key={country.countryCode}
                            >
                                <div
                                    className="d-flex justify-content-between align-items-center w-100 header"
                                    onClick={() => toggleExpand(country.countryCode)}
                                >
                                    <span>
                                        <span className="fas fa-earth me-2" />
                                        {country.countryCode} - {regions.find(({code}) => code === country.countryCode).name}
                                    </span>
                                    <Button size="sm" color="danger" outline hidden={expandedCountryCode === country.countryCode}
                                            onClick={(e) => showDeleteModal(e, country.countryCode)}>
                                        <span className="fas fa-trash"/> Remove
                                    </Button>
                                </div>

                                {expandedCountryCode === country.countryCode && (
                                    <div className="mt-2 w-100">
                                        <DefaultsTable defaults={country.defaults} country={country.countryCode} onSave={handleSaveCountry(index)} />
                                    </div>
                                )}
                            </ListGroupItem>
                        ))}

                    </ListGroup>
                </Fragment>
            )}

            <CountrySelect
                name="countries"
                hideInput
                countries={Countries.regions}
                onChange={handleAddCountries}
                placeholder="Select countries"
                modal={addCountriesModal}
                toggle={() => setAddCountriesModal(!addCountriesModal)}
            />

            <EditModal
                isOpen={editModal.open}
                toggle={() => setEditModal(prev => ({...prev, open: !prev.open}))}
                dataType={editModal.dataType}
                label={editModal.label}
                value={editModal.value}
                onSave={onSaveEditModal}
            />

            <Modal isOpen={deleteModal.isOpen} toggle={closeModal}>
                <ModalHeader toggle={closeModal}>Confirm Deletion</ModalHeader>
                <ModalBody>
                    Are you sure you want to delete this country and its default settings?
                    <h3 className="mt-3 text-center">{deleteModal.code} - {regions.find(({code}) => code === deleteModal.code)?.name}</h3>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" outline onClick={closeModal}>Cancel</Button>
                    <Button color="danger" onClick={() => handleDeleteCountry(deleteModal.code)}>Delete</Button>{' '}
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default DistributorPage;
