import React from 'react';
import {Row, Col} from 'reactstrap';
import {NavLink} from 'react-router-dom';
import TierBadge from '../TierBadge';
import {computeOrderPrice} from '../../utils/credits';
import OrderStatusBadge from '../OrderStatusBadge';

import './OrderCard.scss'
import {imgUrl} from '../../utils/api-request';
import {useSelector} from 'react-redux';

const OrderCard = ({ order }) => {
    const { user } = useSelector(state => state.auth);

    return (
        <NavLink to={`/orders/${order._id}`} className="text-decoration-none text-white">
            <Row className="order-card-component py-3 mx-0 my-3">
                <Col xs="auto">
                    {order.movie.poster ? (
                        <img
                            className="logo-img"
                            width={100}
                            alt={order.movie.title}
                            src={imgUrl(order.movie?.poster?.imageUrl)}
                        />
                    ): (
                        <div
                            style={{width: '100px', height: '141px'}}
                            className="logo-img d-flex justify-content-center flex-column text-center opacity-50"
                        ><span className="fas fa-chain-broken text-primary mb-2"/>Poster N/A</div>
                    )}
                </Col>
                <Col>
                <div className="d-flex justify-content-between align-items-center">
                        <div className="title text-capitalize text-primary ">
                            {order.movie.title}

                            {user && ['root', 'bac-user', 'bac-admin'].includes(user.role) && (
                                <div className="organization d-block d-md-inline-block p-1 ps-2 pe-3 ms-2
                                text-uppercase text-secondary">
                                    <span className="fas fa-building me-2 text-primary"/>
                                    {order.organization.name}
                                </div>
                            )}

                            <br/>
                            <div className="order-credits">
                                <span className="fas fa-fw fa-clapperboard me-2"/>
                                {order.version?.name}
                            </div>
                        </div>

                        <OrderStatusBadge status={order.statusWorkflow} isCard/>
                </div>

                    <div className="d-flex justify-content-start gap-3 mt-3">
                    {user && ['root', 'bac-user', 'bac-admin'].includes(user.role) && (
                            <div>
                                <TierBadge tier={order.organization.tier}/>
                            </div>
                        )}

                    <div className="order-credits">
                        <span className="fas fa-fw fa-coins me-2"/>
                        Price: {computeOrderPrice(order.version.conditionalPrice)} credits
                    </div>

                    {order.screeningDate && (
                        <div className="order-credits">
                            <span className="fas fa-fw fa-calendar-days me-2"/>
                            Screening at {new Date(order.screeningDate).toLocaleDateString()}
                        </div>
                    )}

                </div>
                <div>
                {order.purchasedAt && order.purchasedBy && (
                    <div className='user mt-3 ps-2 text-uppercase'>{`${order.purchasedBy.firstName} ${order.purchasedBy.lastName} - ${new Date(order.purchasedAt).toLocaleString()}`}</div>
                )}
                </div>
                </Col>
            </Row>
        </NavLink>
    );
};

export default OrderCard;
