import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import OrderCard from '../../components/OrderCard';
import Loading from '../../components/Loading';
import {nestedSearch} from '../../utils/nestedSearch';
import { fetchOrders, fetchAllOrders} from '../../slices/orders.slice';
import {useLocation} from 'react-router-dom';
import PageHeader from '../../components/PageHeader';

const Orders = () => {
    const { list: orders, status, allOrders } = useSelector(state => state.orders);
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);
    const [filteredValues, setFilteredValues] = useState([]);
    const [filterBy, setFilterBy] = useState({});
    const [searchValue, setSearchInput] = useState('');

    const location = useLocation();

    useEffect(() => {
        if (!filteredValues?.length){
            setFilteredValues(orders);
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(fetchOrders(filterBy));
    }, [dispatch, filterBy]);

    useEffect(() => {
        dispatch(fetchAllOrders({}));
    }, [dispatch]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const filters = {};

        for (const [key, value] of searchParams.entries()) {
            if (key === 'search') {
                filters.search = value;

            } else if (key === 'status') {
                filters.status = value.split(',');

            } else if (key === 'movies') {
                filters.movies = value.split(',');
            } else if (key === 'from') {
                filters.from = [value];
            } else if (key === 'until') {
                filters.until = [value];

            } else if (key === 'organization') {
                filters.organization = value.split(',');
            }
        }

        setFilterBy(filters);

    }, [location.search]);

    useEffect(()=>{

        let filteredOrders = [...orders]
        if ( filterBy?.status?.length  ) {
            filteredOrders = filteredOrders.filter(order =>filterBy?.status.includes(order.statusWorkflow))
        }
        if ( filterBy?.movies?.length  ) {
            filteredOrders = filteredOrders.filter(order =>filterBy?.movies.includes(order.movie.title))
        }
        if (filterBy?.from){
            filteredOrders = filteredOrders.filter(order =>order.screeningDate > filterBy?.from)
        }
        if (filterBy?.until){
            filteredOrders = filteredOrders.filter(order =>order.screeningDate < filterBy?.until)
        }
        if ( filterBy?.organization?.length ) {
            filteredOrders = filteredOrders.filter(order =>filterBy?.organization.includes(order.organization?.name))
        }
        setFilteredValues(filteredOrders);
    },[filterBy, orders])

    const handleSearch = (event)=>{
        event.preventDefault();
        const query = event.target.value.toLowerCase()
        setSearchInput(query);
        const searchFields = [
            'status',
            {path: 'movie', fields: ['title']}
        ]
        let filteredData = nestedSearch([...orders], searchFields, query)
        setFilteredValues(filteredData);
    }

    const options = {
        status:[...new Set(allOrders?.map(order => ( order.status)))].filter(e=>e !== undefined),
        movies:[...new Set(allOrders?.map(order => ( order.movie.title)))].filter(e=>e !== undefined),
        'screening date': {}
    };
    if (['root', 'bac-admin', 'bac-user'].includes(user?.role)) {
        options.organization = [...new Set(allOrders?.map(order=>order.organization?.name))].filter(e=>e !== undefined)
    }

    const hirePeriodtoMs = (version)=>{
        const hToMs = 3600 * 1000
        if (version.hirePeriodType === 'days'){
            return version.defaultHirePeriod * 24 * hToMs
        }else {
            return version.defaultHirePeriod * hToMs
        }
    }

    let currentOrders = []
    let pastOrders = []
    const now = new Date()
    filteredValues.forEach(order => {
        if (order.screeningDate){
            const screeningDate = new Date(order.screeningDate)
            const dateThreshold = new Date(screeningDate.getTime() + (hirePeriodtoMs(order.version)) )

            if (now < dateThreshold){
                currentOrders.push(order)
            } else  {
                pastOrders.push(order)
            }
        } else {
            currentOrders.push(order)
        }
    });

    const isLoading = ['idle', 'pending'].includes(status);

    return (
        <div className="page px-4 py-3 d-flex flex-column vh-100 overflow-y-auto">
            <PageHeader
                title='orders'
                searchBar={{searchValue, handleSearch}}
                filters={{filterBy, options}}
                />

            <div className="contentContainer">

                {`showing ${filteredValues?.length} result${filteredValues?.length === 1 ? '' : 's'}`}


                {isLoading && <Loading/>}
                {currentOrders.length > 0 &&<h3 className="text-uppercase text-primary mt-3">current orders</h3>}
                    {!isLoading && currentOrders.map(order => (
                    <OrderCard key={order._id} order={order}/>
                ))}

                {pastOrders.length > 0 && <h3 className="text-uppercase text-primary mt-3">Past orders</h3>}
                    {!isLoading && pastOrders.map(order => (
                    <OrderCard key={order._id} order={order}/>
                ))}

                <div className="clearfix py-4" />
            </div>
        </div>

    );
};

export default Orders;
