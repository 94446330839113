import React, {Fragment, useEffect, useState} from 'react';
import {NavLink, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {fetchOrganization, fetchOrganizationContent} from '../../slices/organizations.slice';
import {toast} from 'react-toastify';
import {formatBytes} from '../../components/DiskUsage';
import {Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader, Col, Row} from 'reactstrap';
import {nestedSearch} from '../../utils/nestedSearch';
import {ingestCPL} from '../../slices/content.slice';
import './OrganizationContent.scss'
import PageHeader from '../../components/PageHeader';
import {ContentCard} from '../../components/ContentCard';

const ContentManager = () => {
    const {organizationId} = useParams();
    const dispatch = useDispatch();
    const token = useSelector(state => state.auth.token);
    const user = useSelector(state => state.auth.user);
    const organization = useSelector((state) => state.organizations.organization);
    const organizationContent = useSelector(state => state.organizations.organizationContent)

    const [searchValue, setSearchInput] = useState('');
    const [displaying, setDisplaying] = useState('mediablock');
    const [filteredValues, setFilteredValues] = useState([]);
    const [modal, setModal] = useState({isOpen: false, content: null});

    const toggleContentModal = (content = null) => () => {
        setModal({isOpen: !modal.isOpen, content});
    };

    const handleSearch = (event) => {
        event.preventDefault();
        const query = event.target.value.toLowerCase()
        setSearchInput(query);
        // Define the fields to search through, including nested fields
        const searchFields = [
            'type',
            'uuid',
            'cpl_uuid',
            'contentTitleText',
            {path: 'movieVersion.movie', fields: ['title', 'synopsis']},
        ];
        const content = organizationContent.filter(cont => cont.location === displaying)
        let filteredData = nestedSearch(content, searchFields, query)
        setFilteredValues(filteredData);
    };

    const handleDisplayingToggle = (location) => {
        setDisplaying(location);
    };

    useEffect(() => {
        if (!user) return;
        let orgId = organizationId;
        if (organizationId === 'own' || !organizationId) {
            orgId = user.parentOrganization._id;
        }
        dispatch(fetchOrganization({token, orgId}));
        dispatch(fetchOrganizationContent({token, orgId}));
    }, [token, dispatch, organizationId, user, displaying]);

    useEffect(() => {
        setFilteredValues(organizationContent.filter(org => org.location === displaying))
    }, [organizationContent, displaying]);

    const handleIngest = async (cpl) => {
        try {
            const promise = dispatch(ingestCPL(cpl)).unwrap();
            toast.promise(promise, {
                pending: 'Updating content...',
                success: 'CPL moved to Mediablock',
                error: `Error updating content.`,
            });

            await promise;

            setFilteredValues(organizationContent.filter(content => content._id !== cpl._id && content.location === displaying));
        } catch (error) {

        } finally {
            setModal({isOpen: !modal.isOpen, content: null});
        }
    };

    const cplInfo = [
        {label: 'id', data: modal.content?._id},
        {label: 'type', data: modal.content?.type},
        {label: 'title', data: modal.content?.contentTitleText},
        {label: 'cpl_uuid', data: modal.content?.cpl_uuid},
        {label: 'location', data: modal.content?.location},
        {label: 'On machine since', data: modal.content?.onMachine?.split('T')[0]},
        {label: 'File Size', data: formatBytes(modal.content?.fileSize)},
        {label: 'DCP Size', data: modal.content?.dcpSize ? formatBytes(modal.content?.dcpSize) : 'N/A'},


        {
            label: 'KDM', data: {
                uuid: modal.content?.KDM_uuid || '',
                from: modal.content?.KDM_notValidBefore?.split('T')[0] || '',
                until: modal.content?.KDM_notValidAfter?.split('T')[0] || '',
            }, condition: ['root', 'bac-user', 'bac-admin'].includes(user?.role),
        },
    ];

    const renderField = field => {
        if (field.condition === undefined || field.condition === true) {
            return <Col lg={6} key={field.label}>
                <h5 className="mt-3 text-uppercase">{field.label}</h5>
                {((typeof field.data !== 'string') ? (
                    <>{Object.keys(field.data).map(key => <Row key={key}>
                        <Col xs={3} lg={2} className="ps-4">{`${key.toUpperCase()}: `}</Col>
                        <Col className="data ps-lg-4 ps-sm-2">{`${field.data[key]}`}</Col>
                    </Row>)}
                    </>
                ) : (
                    <div className="data">{field.data || `no ${field.label} available`}</div>
                ))}
            </Col>
        }
    };

    const locationSwitch = (shrink) => {
        return (
            <ButtonGroup size="sm" className="toggleButtons mt-3 p-0">
                <Button color="primary" outline={displaying !== 'mediablock'}
                        onClick={() => handleDisplayingToggle('mediablock')}>
                    {shrink ? <span className="fas fa-cube px-2"/> :
                        <div><span className="fas fa-cube me-2"/> On Mediablock</div>}
                </Button>
                <Button color="primary" outline={displaying !== 'BAC_server'}
                        onClick={() => handleDisplayingToggle('BAC_server')}>
                    {shrink ? <span className="fas fa-hard-drive px-2"/> :
                        <div><span className="fas fa-hard-drive me-2"/> On BAC Server</div>}
                </Button>
            </ButtonGroup>
        );
    };

    return organization && (
        <div className=" page px-4 py-3 d-flex flex-column vh-100 overflow-y-auto">
            {organizationId && (
                <NavLink to={`/organizations/${organizationId}`} className="text-decoration-none">
                    <span className="fas fa-chevron-left me-2"/> {organization.name}
                </NavLink>
            )}
            <PageHeader
                title="content"
                searchBar={{searchValue, handleSearch}}
                otherActions={locationSwitch}
            />

            <div className="my-3 contentContainer">
                <div
                    className="mb-3"> {`showing ${filteredValues.length} of `}{organizationContent.filter(cont => cont.location === displaying).length} records
                </div>
                {filteredValues?.map(content => (
                    <ContentCard key={content._id} content={content} toggleContentModal={toggleContentModal}/>
                ))}

                {filteredValues?.length === 0 && (
                    <span>- No content available on {displaying === 'mediablock' ? 'mediablock' : 'BAC Server'} -</span>
                )}
            </div>

            {modal.isOpen && (
                <Modal isOpen={modal.isOpen} toggle={toggleContentModal()} size="lg" modalClassName="mw-100" scrollable>
                    <ModalHeader toggle={toggleContentModal()} className="text-primary">
                        <span className="fas fa-file me-2"/>
                        {modal.content.type.toUpperCase()} information
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            {cplInfo.map(renderField)}
                        </Row>
                    </ModalBody>
                    <ModalFooter className="d-flex justify-content-between align-items-center">
                        {modal.content.location === 'BAC_server' && (
                            <Button color="primary" outline onClick={() => {
                                handleIngest(modal.content)
                            }}>
                                <span className="fas fa-upload me-2"/>
                                Ingest CPL to Mediablock
                            </Button>
                        )}
                        <Button color="primary" outline onClick={toggleContentModal()}>Close</Button>
                    </ModalFooter>
                </Modal>
            )}
        </div>
    );
};

export default ContentManager;
