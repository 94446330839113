import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../api';

export const fetchMovies = createAsyncThunk('movies/fetch', async () => {
    const response = await api.get('/movies');
    return response.data;
});

export const fetchMovie = createAsyncThunk('movies/fetchMovie', async ({ movieId }) => {
    const response = await api.get(`/movies/${movieId}`);
    return response.data;
});

export const updateMovie = createAsyncThunk('movies/update', async ({ token, movieId, body }) => {
    const response = await api.put(`/movies/${movieId}`, body);
    return response.data;
});

export const deleteMovie = createAsyncThunk('movies/delete', async ({ movieId }) => {
    const response = await api.delete(`/movies/${movieId}`);
    return response.data;
});


export const postNewVersion = createAsyncThunk('movies/new-version', async ({ movieId, body }) => {
    const response = await api.post(`/movies/${movieId}/versions`, body);
    return response.data;
});

export const updateVersion = createAsyncThunk('movies/update-version', async ({ movieId, versionId, body }) => {
    const response = await api.put(`/movies/${movieId}/versions/${versionId}`, body);
    return response.data;
});

export const deleteVersion = createAsyncThunk('movies/delete-version', async ({ movieId, versionId }) => {
    const response = await api.delete(`/movies/${movieId}/versions/${versionId}`);
    return response.data;
});

export const fetchHomefeed = createAsyncThunk('movies/homefeed', async (type) => {
    const response = await api.get(`/homefeed/${type}`);
    return response.data;
});

const moviesSlice = createSlice({
    name: 'movies',
    initialState: {
        list: [],
        status: 'idle', // 'idle' | 'loading' | 'success' | 'failed'
        error: null,
        movie: null,
        movieStatus: 'idle', // 'idle' | 'loading' | 'success' | 'failed',
        movieError: null,
        newVersionStatus: 'idle',
        newVersionError: null,
        isDeleting: false,
        homefeeds: {
            'highlight': { data: null, status: 'idle', error: null },
            'coming-soon': { data: null, status: 'idle', error: null },
            'now-showing': { data: null, status: 'idle', error: null },
            'purchases': { data: null, status: 'idle', error: null },
            'trailers': { data: null, status: 'idle', error: null },
        }
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchMovies.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchMovies.fulfilled, (state, action) => {
                state.status = 'success';
                state.list = action.payload;
                state.error = null;
            })
            .addCase(fetchMovies.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'An error occurred';
            })

            .addCase(fetchMovie.pending, (state) => {
                state.movieStatus = 'loading';
            })
            .addCase(fetchMovie.fulfilled, (state, action) => {
                state.movieStatus = 'success';
                state.movie = action.payload;
                state.movieError = null;
            })
            .addCase(fetchMovie.rejected, (state, action) => {
                state.movieStatus = 'failed';
                state.movieError = action.error.message || 'An error occurred';
            })

            .addCase(updateMovie.fulfilled, (state, action) => {
                state.movieStatus = 'success';
                state.movie = action.payload;
                state.movieError = null;
            })
            .addCase(updateMovie.rejected, (state, action) => {
                state.movieStatus = 'failed';
                state.movieError = action.error.message || 'An error occurred';
            })

            .addCase(postNewVersion.pending, (state) => {
                state.newVersionStatus = 'loading';
            })
            .addCase(postNewVersion.fulfilled, (state, action) => {
                state.newVersionStatus = 'success';
                if (state.movie?.versions && Array.isArray(state.movie.versions)) {
                    state.movie.versions.push(action.payload);
                }
            })
            .addCase(postNewVersion.rejected, (state, action) => {
                state.newVersionStatus = 'failed';
                state.newVersionError = action.error.message || 'An error occurred';
            })

            .addCase(deleteVersion.pending, (state) => {
                state.isDeleting = true;
            })
            .addCase(deleteVersion.fulfilled, (state, action) => {
                const { versionId } = action.meta.arg;
                state.movie.versions = state.movie.versions.filter(version => version._id !== versionId);
                state.isDeleting = false;
            })
            .addCase(deleteVersion.rejected, (state, action) => {
                state.isDeleting = false;
                state.newVersionError = action.error.message || 'An error occurred';
            })

            .addCase(updateVersion.pending, (state) => {
                state.newVersionStatus = 'loading';
            })
            .addCase(updateVersion.fulfilled, (state, action) => {
                const { versionId } = action.meta.arg;
                state.movie.versions = state.movie.versions.map(version =>
                    version._id === versionId ? action.payload : version
                );
                state.newVersionStatus = 'success';
            })
            .addCase(updateVersion.rejected, (state, action) => {
                state.newVersionStatus = 'failed';
                state.newVersionError = action.error.message || 'An error occurred';
            })

            .addCase(fetchHomefeed.pending, (state, action) => {
                const type = action.meta.arg;
                state.homefeeds[type].status = 'loading';
            })
            .addCase(fetchHomefeed.fulfilled, (state, action) => {
                const type = action.meta.arg;
                state.homefeeds[type].data = action.payload;
                state.homefeeds[type].status = 'success';
            })
            .addCase(fetchHomefeed.rejected, (state, action) => {
                const type = action.meta.arg;
                state.homefeeds[type].status = 'failed';
                state.homefeeds[type].error = action.error.message || 'An error occurred';
            })
        ;
    },
});

export default moviesSlice.reducer;
