import React from "react";
import {Col, Input, Label, Row} from 'reactstrap';

const IpAddressForm = ({ handleChange, settings }) => {

    return [1,2,3,4,5].map(i => (
        <Row className="mt-3" key={i}>
            <Col className="d-flex flex-row align-items-center">
                <Label for={`ipAddress${i}`} style={{ width: '250px' }}>
                    <span className="fas fa-network-wired fa-fw me-2 text-primary"/>
                    IP Address {i}
                </Label>
                <Input
                    id={`ipAddress${i}`}
                    name={`ipAddress${i}`}
                    value={settings[`ipAddress${i}`] || ''}
                    onChange={handleChange}
                />
            </Col>
        </Row>
    ));
};

export default IpAddressForm;